<template>
  <div>
    <label class="fr-label" for="text-input-text"
      >SIREN (optionnel, égal au SIREN courant à défaut)</label
    >
    <input
      v-model="creance.bouchon.siren"
      class="fr-input"
      type="text"
      id="text-input-text"
      name="text-input-text"
    />
    <label class="fr-label" for="text-input-text"
      >complementIdentifiant (idOcfiPseudonymise)</label
    >
    <input
      v-model="creance.contexte.idOcfiPseudonymise"
      class="fr-input"
      type="text"
      id="text-input-text"
      name="text-input-text"
    />
    <label class="fr-label" for="text-input-text" required
      >codeRedevabilite (codeOBF)</label
    >
    <input
      v-model="creance.contexte.codeOBF"
      class="fr-input"
      type="text"
      id="text-input-text"
      name="text-input-text"
    />
    <label class="fr-label" for="text-input-text"
      >Date début période de référence de la créance (dateDebut)</label
    >
    <input
      v-model="creance.periodeReference.dateDebut"
      class="fr-input"
      type="text"
      id="text-input-text"
      name="text-input-text"
      placeholder="AAAA-MM-JJ"
    />
    <label class="fr-label" for="text-input-text"
      >Date fin période de référence de la créance (dateFin)</label
    >
    <input
      v-model="creance.periodeReference.dateFin"
      class="fr-input"
      type="text"
      id="text-input-text"
      name="text-input-text"
      placeholder="AAAA-MM-JJ"
    />
    <label class="fr-label" for="text-input-text"
      >DLP (dateLimitePaiement)</label
    >
    <input
      v-model="creance.dateLimitePaiement"
      class="fr-input"
      type="text"
      id="text-input-text"
      name="text-input-text"
      placeholder="AAAA-MM-JJ"
    />
    <br />
    <button class="fr-btn" @click="payer()">Payer</button>
  </div>
</template>
<script>
import SkipLinkMixin from '../mixins/skip-link-mixin';
import { isFeatureActive } from '../utils/methodsUtils';

export default {
  components: {},
  mixins: [SkipLinkMixin],
  data() {
    return {
      creance: { contexte: {}, periodeReference: {}, bouchon: {} }
    };
  },
  computed: {
    c1() {
      return true;
    }
  },
  methods: {
    async payer() {
      await this.$store
        .dispatch('paiement/setCreanceEnCours', this.creance)
        .then(() => {
          this.$router.push({ name: "Paiement de l'impot" });
        });
    }
  },
  async mounted() {
    if (!isFeatureActive('PCR2-7477')) {
      this.$router.push({ name: 'Erreur 404' });
    }
  }
};
</script>
<style lang="scss" scoped></style>
